import * as React from 'react';
import LoginButtonComponent from './LoginButtonComponent';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';

import * as AppState from '../../store/AppState';
import * as Models from '../../models/AppState';
import { getCurrentTimestamp } from 'helpers/utils';
type store = Models.AppState & typeof AppState.actionCreators;
var Loader = require('react-loader');

class SignInComponent extends React.Component<
    any,
    {
        username: string;
        password: string;
        currentUser: any;
        message: string;
        isLoginSuccess: boolean;
    },
    store
> {
    constructor(props: any) {
        super(props);

        this.state = {
            username: '',
            password: '',
            currentUser: null,
            message: '',
            isLoginSuccess: false
        };

        this.onChangeUserName = this.onChangeUserName.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onLoginSuccess = this.onLoginSuccess.bind(this);

        this.isFromMobileBrowser = this.isFromMobileBrowser.bind(this);
    }

    componentDidMount() {
        console.log("this.props.updateEnv(true, 'GET')");
        //console.log((this.props.location.state !== undefined) ? this.props.location.state.from : '')
        this.props.updateEnv(true, 'GET');
    }

    private onChangeUserName(e: any) {
        const { name, value } = e.target;
        //console.log('username:' + value)
        this.setState({
            username: value
        });
    }

    private onChangePassword(e: any) {
        const { name, value } = e.target;
        //console.log('password:' + value)
        this.setState({
            password: value
        });
    }

    public onLoginSuccess() {
        this.setState({ isLoginSuccess: true });
    }

    isFromMobileBrowser() {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];

        return toMatch.some(toMatchItem => {
            return navigator.userAgent.match(toMatchItem);
        });
    }

    render() {
        let loginObject = this.state;
        if (this.state.isLoginSuccess) {
            if (this.props.AnonymousMode == true) {
                this.props.blindLogin(true);
            } else {
                return <Redirect to="/" />;
            }
        }
        console.log(this.props.ssoEnv);
        console.log(this.props.location);
        var state = '';
        if (
            this.props.location !== undefined &&
            this.props.location.state !== undefined
        ) {
            state = this.props.location.state.from;
        }

        const isMobile = this.isFromMobileBrowser();
        if (isMobile && this.props.ssoEnv) {
            (window as any).top.location.href = this.props.ssoEnv.SSO_WEB +
                btoa(state) +
                '&token=' +
                this.props.ssoEnv.SSO_REDIRECT_TOKEN;
        }

        return (
            //<form>
            //    <div className="row justify-content-center" style={{ marginTop:"100px" }}>
            //        <div className="col-lg-4 col-md-6 col-sm-12">
            //            <h3>Sign In</h3>

            //            <div className="form-group">
            //                <label>Username</label>
            //                <input type="text" className="form-control" placeholder="Enter username" name="username" onChange={this.onChangeUserName} />
            //            </div>

            //            <div className="form-group">
            //                <label>Password</label>
            //                <input type="password" className="form-control" placeholder="Enter password" name="password" onChange={this.onChangePassword} />
            //            </div>

            //            <div className="form-group">
            //                <div className="custom-control custom-checkbox">
            //                    <input type="checkbox" className="custom-control-input" id="customCheck1" />
            //                    <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
            //                </div>
            //            </div>

            //            <LoginButtonComponent loginObject={loginObject} onLoginSuccess={this.onLoginSuccess.bind(this)} />
            //            <p className="forgot-password text-right">
            //                Forgot <a href="#">password?</a>
            //            </p>

            //        </div>
            //    </div>
            //</form>
            //<Loader loaded={this.props.ssoEnv == undefined ? true : false} >
            <div style={{ height: '90dvh', width: '100dvw' }}>
                {/*<iframe src="https://scg-sso.sonarinno.com/auth/signin" />*/}
                {this.props.ssoEnv != undefined && (
                    <iframe
                        src={
                            this.props.ssoEnv.SSO_WEB +
                            btoa(state) +
                            '&token=' +
                            this.props.ssoEnv.SSO_REDIRECT_TOKEN +
                            '&timestamp=' +
                            getCurrentTimestamp()
                        }
                        style={{ width: '100%', height: '100%' }}
                    />
                )}
            </div>
            //</Loader>
        );
    }
}

export default connect((state: ApplicationState) => ({ ...state.appState }), {
    ...AppState.actionCreators
})(SignInComponent);
